@font-face {
  font-family: "NanumSquare Neo";
  font-style: normal;
  font-weight: 300;
  src: url("./NanumSquareNeoOTF-aLt.otf") format("opentype");
}

@font-face {
  font-family: "NanumSquare Neo";
  font-style: normal;
  font-weight: normal;
  src: url("./NanumSquareNeoOTF-bRg.otf") format("opentype");
}

@font-face {
  font-family: "NanumSquare Neo";
  font-style: normal;
  font-weight: bold;
  src: url("./NanumSquareNeoOTF-cBd.otf") format("opentype");
}

@font-face {
  font-family: "NanumSquare Neo";
  font-style: normal;
  font-weight: 800;
  src: url("./NanumSquareNeoOTF-dEb.otf") format("opentype");
}

@font-face {
  font-family: "SB font";
  font-style: normal;
  font-weight: normal;
  src: url("./SBOTF_M.otf") format("opentype"),
    url("./SB_M.ttf") format("truetype");
}
@font-face {
  font-family: "SB font";
  font-style: normal;
  font-weight: bold;
  src: url("./SBOTF_B.otf") format("opentype"),
    url("./SB_B.ttf") format("truetype");
}

@font-face {
  font-family: "SB font";
  font-style: normal;
  font-weight: 300;
  src: url("./SBOTF_L.otf") format("opentype"),
    url("./SB_L.ttf") format("truetype");
}
